import React, { Component } from "react"

import IconLinkCard from "../components/IconLinkCard"
import SiteContext from "../context/SiteContext"
import FixedMenu from "../components/FixedMenu" 
import SEO from "../components/seo"


class Welcome extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props)
        this.state = {
            site: ''
        }
    }

    componentDidMount() {
        let site = 'senergy'
        
        this.context.changeSite(site)
        this.setState({ site })
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <>
                        <SEO title="Welcome" />
                        <div className={`wrapper wrapper--home senergy`}>
                            <div className="content-wrapper--padded">
                                <p className="home-intro">Welcome to the Senergy app, your go-to-resource for product and technical information, while you’re on the go!</p>
                                <div className="icon-link-cards">
                                    <IconLinkCard title="Systems"  url='/systems' icon="systems" />
                                    <IconLinkCard title="Products"  url='/products' icon="products" />
                                    <IconLinkCard title="Videos"  url='/videos' icon="video-player" />
                                    <IconLinkCard title="Literature" url='/literature' icon="documents" />
                                    <IconLinkCard title="Contact Us"  url='/contact' icon="contact" />
                                </div>

                                <span className="spacer"></span>
                            </div>
                            <FixedMenu active="home" site="senergy" /> 
                        </div>
                    </>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default Welcome